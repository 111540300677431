<template>
  <wit-block-content-container class="services-desc-panel" :style="{'background-color': bgColor}">
    <div class="panel-content w-l-flex w-l-ai-center w-l-jc-between">
      <div class="left-content" :style="{order: imgOrder}">
        <div class="panel-title fc-d3">
          <p>{{ title }}</p>
        </div>
        <div class="description fc-d9 font-18">
          <p>{{ description }}</p>
        </div>
      </div>
      <div class="right-content">
        <img :src="imgUrl" alt="site" height="400">
      </div>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";

  export default {
    name: "ServicesDescPanel",
    components:{
      WitBlockContentContainer
    },
    props:{
      mode: {
        type: String,
        default: () => "img-right"
      },
      title: {
        type: String,
        default: () => ""
      },
      description: {
        type: String,
        default: () => ""
      },
      imgUrl:{
        type: String,
        default: () => ""
      },
      bgColor: {
        type: String,
        default: () => 'rgb(255, 255, 255)'
      }
    },
    computed:{
      imgOrder(){
        return this.mode === "img-right" ? 1 : 3
      }
    }
  }
</script>

<style lang="scss" scoped>
.services-desc-panel{
  .panel-content{
    height: 550px;
    padding: 100px 0;
    .left-content{
      max-width: 50%;
      .description{
        margin-top: 20px;
        line-height: 35px;
        letter-spacing: 1.5px;
      }
    }
    .right-content{
      order: 2;
    }
  }
}
</style>
