<template>
  <div class="production-services-wit">
    <el-main class="services-wit-container">
      <img-title-panel bg-img="/images/services/shome.jpg" height="600px" mask-color="rgba(0,0,0,0.3)">
        <div class="page-title">土地租赁种植</div>
        <div class="page-title-tip">用户可租赁土地，委托农场托管或自己亲身体验耕作的快乐，农场可安装监控，物联设备为客户提供远程监控服务，提供可靠的实时信息展示。</div>
      </img-title-panel>
      <services-desc-panel v-for="(item, index) in serviceDescs"
                           :key="index"
                           :title="item.title"
                           :description="item.description"
                           :img-url="item.imgUrl"
                           :mode="getMode(index)"
                           :bg-color="getBgColor(index)"/>
    </el-main>
  </div>
</template>

<script>
  import ImgTitlePanel from "@/components/content/ImgTitlePanel/ImgTitlePanel";
  import ServicesDescPanel from "@/views/services/ServicesDescPanel/ServicesDescPanel";
  export default {
    name: "Services",
    components:{
      ImgTitlePanel,
      ServicesDescPanel
    },
    data(){
      return {
        serviceDescs: [
          {
            title: "租赁种植",
            description: "用户拥有自主选择种苗与土地的权力，高度定制化的服务，完美的农耕体验。",
            imgUrl: "/images/services/gp-001.jpg"
          },
          {
            title: "360度呵护",
            description: "利用物联网技术，获区现场的土肥湿度等多种环境数据，轻松掌握农作物状态。",
            imgUrl: "/images/services/gp-002.png"
          },
          {
            title: "超高的可回溯性",
            description: "用户可以24小时实时直播菜地状态，从播种，生长，收获到物流运输，全程实时追溯，才能有安全可靠的品质保证。",
            imgUrl: "/images/services/gp-003.jpg"
          },
          {
            title: "AI护航",
            description: "利用前沿的AI技术，实时调控种植策略，保证每一次的喜悦收获。",
            imgUrl: "/images/services/gp-004.png"
          }
        ]
      }
    },
    methods: {
      getBgColor(index){
        return (index%2)===0 ? "rgb(255.255,255)" : "#fbfbfb"
      },
      getMode(index){
        return (index%2)===0 ? "img-right" : "img-left"
      }
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    }
  }
</script>

<style lang="scss" scoped>
.production-services-wit{
  .services-wit-container{
    padding: 0 !important;
    .page-title{
      margin-bottom: 20px;
    }
  }
}
</style>
